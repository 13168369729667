<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Dettaglio presenza</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
        </ion-toolbar>
      </ion-header>
      <div id="container">
        <div>
          <div>
            <ion-label> Data:</ion-label>
            <ion-item>
              <ion-input :disabled="$store.state.userRole!=7 ? true : false"></ion-input>
            </ion-item>
          </div>
          <div>
            <ion-label>Inizio attività: </ion-label>
            <ion-item>
              <ion-input :disabled="$store.state.userRole!=7 ? true : false"></ion-input>
            </ion-item>
          </div>
          <div>
          <ion-label>Fine attività:</ion-label>
            <ion-item>
             <ion-input :disabled="$store.state.userRole!=7 ? true : false"></ion-input>
            </ion-item>
          </div>
          <div>
            <ion-label>Ore totali:</ion-label>
            <ion-item>
              <ion-input :disabled="$store.state.userRole!=7 ? true : false"></ion-input>
            </ion-item>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>
<script>
  import { 
    IonBackButton,
    IonButtons, 
    IonContent, 
    IonHeader,
    IonItem, 
    IonInput,
    IonLabel,
    IonMenuButton, 
    IonPage, 
    IonTitle, 
    IonToolbar 
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonBackButton,
      IonButtons,
      IonContent,
      IonItem,
      IonInput,
      IonHeader,
      IonLabel,
      IonMenuButton,
      IonPage,    
      IonTitle,
      IonToolbar
    },
    data: function(){
      return{
        user:{
            name:'',
            email:'',
            password:'',
            role:''
        }
      }
    },
    ionViewWillEnter: function(){

    }
  }
</script>

<style scoped>
  #container{
    width:100%;
  }
</style>